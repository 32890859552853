import React, { FC, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { useState } from "react";
import {
  getTrendingProducts,
  type TrendingProduct,
} from "utils/trendingProducts";
import Image, { StaticImageData } from "next/image";
import { LoadingTrendingProducts } from "../loading/LoadingTrendingProducts";
import SponsoredImage from "@/img/TurmericSoap.jpeg";
import NewImage from "@/img/404b.png";
import Link from "next/link";
import { Json } from "lib/supabase";

interface TrendingProps {
  setSearchQuery: (searchQuery: string) => void;
}

// Extends TrendingProduct, changes image_link, adds isSponsored and sponsoredLink
interface TrendingSponsored extends Omit<TrendingProduct, "image_link"> {
  image_link: string | StaticImageData;
  isSponsored?: boolean;
  sponsoredLink?: string;
  tag: Json;
  brand: string;
}
const sponsoredProducts: TrendingSponsored[] = [
  {
    upc: "-1",
    product_name: "Turmeric Glow (Dark Spot Corrector) Soap",
    image_link: SponsoredImage,
    trending_date: "N/A",
    isSponsored: true,
    product_link:
      "https://a.co/d/bOCI2m6",
    tag: {}, //key: tag order of important, value: description
    brand: "Oh Sweets! Skin Care",
  },
  {
    upc: "-1",
    product_name: "Turmeric Glow (Dark Spot Corrector) Soap",
    image_link: SponsoredImage,
    trending_date: "N/A",
    isSponsored: true,
    product_link:
      "https://a.co/d/bOCI2m6",
    tag: {},
    brand: "Oh Sweets! Skin Care",
  },
];

const TrendingSlider: FC<TrendingProps> = ({ setSearchQuery }) => {
  const [trendingProducts, setTrendingProducts] =
    useState<TrendingSponsored[]>(null);

  useEffect(() => {
    async function fetchTrending() {
      const res =
        (await getTrendingProducts()) as unknown as TrendingSponsored[];
      res.splice(0, 0, sponsoredProducts[0]);
      res.splice(3, 0, sponsoredProducts[1]);
      setTrendingProducts(res);
    }
    fetchTrending();
  }, []);
  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    mobileFirst: true,
    centerMode: false,
    autoplay: true,
    autoplaySpeed: 2500,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 765, // Adjust the breakpoint value as needed
        settings: {
          slidesToShow: 2, // Show one slide on mobile
          slidesToScroll: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 1020, // Adjust the breakpoint value as needed
        settings: {
          slidesToShow: 2, // Show one slide on mobile
          slidesToScroll: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 1024, // Adjust the breakpoint value as needed
        settings: {
          slidesToShow: 2, // Show one slide on mobile
          slidesToScroll: 1,
          centerMode: true,
        },
      },
    ],
  };
  return (
    <Slider {...settings} className="flex h-min">
      {trendingProducts && trendingProducts.length > 1
        ? trendingProducts.map((product) => (
            <TrendingProductsCard
              key={product.upc}
              product={product}
              setSearchQuery={setSearchQuery}
            />
          ))
        : LoadingTrendingProducts}
    </Slider>
  );
};

interface TCardProps {
  product: TrendingSponsored;
  setSearchQuery: (searchQuery: string) => void;
}

const TrendingProductsCard: FC<TCardProps> = ({ product, setSearchQuery }) => {
  return (
    <div
      className="relative mx-2 my-6 flex !h-full flex-col justify-center rounded-2xl border-gray-200 bg-white md:mr-6 md:border-2 md:p-6"
      key={product.upc}
    >
      {/* tag and save*/}
      <div className="">
        <div className="flex items-start justify-between self-stretch px-6"></div>
        <div className="g-[10px] flex flex-1 items-start">
          {product.tag ? (
            <div className="flex hidden items-center rounded-[20px] bg-[#4698CA1A] px-3 py-[11px] text-xs font-semibold text-blue-800">
              {
                product.tag[
                  Object.keys(product.tag)
                    .map(Number)
                    .sort((a, b) => a - b)[0] // shows primary tag
                ]
              }
            </div>
          ) : null}
        </div>
      </div>
      <div className="md:3 flex h-[100px] w-auto justify-center pt-3 md:h-[154px]">
        <Image
          className="h-full max-h-[6.5rem] w-auto max-w-[6.5rem] shrink-0 scale-110 md:max-h-[7.5rem] md:max-w-[7.5rem]"
          src={product.image_link}
          alt={product.product_name}
          width={0}
          height={0}
          sizes="100vw"
          quality={100}
        />
      </div>
      {product.isSponsored ? (
        <div className="flex text-xs font-light text-gray-600">sponsored</div>
      ) : (
        <div className="h-[16px]" />
      )}
      {/* line up all the cards if sponsored is missing*/}
      <div className="flex flex-col gap-2 pt-3">
        {/*  how to get price? since price is in product(store(storeid)) and storeID is a FK
        <div className="flex flex-row items-start justify-between self-stretch">
          <h3>
          {product.sale_price > 0 ? product.sale_price : product.regular_price}
          </h3>
        </div>
        //*/}
        <h3 className="text-clop line-clamp-2 h-[64px] overflow-hidden text-sm font-semibold md:h-12 md:text-base">
          {product.product_name}
        </h3>
        {/* how to get all the brand names when target doesnt provide them another*/}
        <h2 className="hidden text-sm font-medium text-gray-600">
          {product.brand}
        </h2>
        <div className="flex">
          <Link
            className="text-sm font-normal underline"
            href={product.product_link || "#"}
            onClick={(e) => {
              if (!product.product_link) {
                e.preventDefault(); // Prevent navigation since the link is null
                setSearchQuery(product.product_name);
              }
            }}
            target="_blank"
          >
            See details
          </Link>
        </div>
      </div>
    </div>
  );
};

const TrendingProducts: FC<TrendingProps> = ({ setSearchQuery }) => {
  return (
    <div className="mx-6 bg-white py-2 md:mx-20">
      <div className="flex flex-col gap-0">
        <h1 className="text-left text-2xl font-bold text-black md:text-3xl">
          {" "}
          Trending Items
        </h1>
        <div
          className="carousel justify-center font-bold text-black"
          id="trendingProducts"
        >
          <div className="mx-auto flex h-full flex-col md:justify-center md:px-6">
            <TrendingSlider setSearchQuery={setSearchQuery} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrendingProducts;
