import React, { FC } from "react";
import Link from "next/link";
import AdBanner from "@/components/mainComponents/AdBanner";
import Image from "next/image";

// Import SVG components or paths
/*
import UsersGroupIcon from "@/img/users-group.svg";
import MagnifyingGlassIcon from "@/img/magnifying_glass_red.svg";
*/

import CompareIcon from "@/img/compare.svg";
import GroupIcon from "@/img/users-group.svg";
import MagnifyingGlass from "@/img/magnifying_glass_red.svg";

// Define the interface for each option
interface OptionProps {
  title: string;
  description: string;
  iconSrc;
  buttonText: string;
  redirectLink: string;
  altIconDescription: string;
  style?: string;
}

// Define the options
const ways: OptionProps[] = [
  {
    title: "Find Products",
    description:
      "Enter the product you're looking for and your location to find it near you.",
    iconSrc: MagnifyingGlass,
    buttonText: "Search Youdle",
    redirectLink: "",
    altIconDescription: "magnifying glass icon",
  },
  {
    title: "Compare Prices",
    description:
      "Compare the price and availability of an item across various stores at a glance.",
    iconSrc: CompareIcon,
    buttonText: "Compare now",
    redirectLink: "",
    altIconDescription: "compare icon",
  },
  {
    title: "Community",
    description:
      "Can’t find an item? Join the Youdle Nation for help and to help those near you.",
    iconSrc: GroupIcon,
    buttonText: "Join us",
    redirectLink: "https://www.facebook.com/groups/getyoudle",
    altIconDescription: "group icon",
  },
];

// Component for rendering each option
const OptionCol: FC<OptionProps> = (props) => {
  return (
    <div className="flex w-full flex-col items-center justify-center gap-2 pb-6 text-center md:gap-5">
      {/* Render the icon */}
      <div className="icon !mx-auto h-[42] w-[42] !fill-red stroke-red">
        <props.iconSrc />
      </div>
      {/* Title */}
      <div className="text-xl font-bold text-black md:text-2xl">
        {props.title}
      </div>
      {/* Description */}
      <div className="text-md font-normal text-black sm:h-[72px] md:h-auto">
        {props.description}
      </div>
      {/* Button */}
      <Link
        href={props.redirectLink}
        className="h-auto items-center justify-center rounded-[20px] bg-red px-6 py-[11px] text-sm font-bold text-white w-[200px]"
      >
        {props.buttonText}
      </Link>
    </div>
  );
};

// Main component
function WaysToYoudle() {
  return (
    <div className="p-0 pt-0 text-center md:px-20 md:py-0">
      {/* Left-aligned AdBanner */}
      <div className="flex-none md:-ml-20 mb-5 md:mb-1">
        <AdBanner />
      </div>

      <div className="flex pt-5 mb-5 -mt-10 items-center">
        {/* Spacer to balance the layout */}
        <div className="flex-1"></div>

        {/* Centered H1 */}
        <h1 className="text-2xl font-bold text-black md:text-4xl text-center">
          Ways to Youdle
        </h1>

        {/* Extra spacer to balance layout */}
        <div className="flex-1"></div>
      </div>

      <div className="flex flex-col md:flex-row md:gap-3 md:gap-6">
        {ways.map((option, index) => (
          <OptionCol key={index} {...option} />
        ))}
      </div>
    </div>
  );
}

export default WaysToYoudle;
