import { FC } from "react";
import Link from "next/link";

const AdBanner: FC = (props) => {
  return (
    <section className="bg-white px-6 pt-2 md:px-12">
      <p className="bg-white text-left text-blue-600 underline">
        <Link href="mailto:marketing@youdleit.com">
          Click here to advertise
        </Link>
      </p>
      <div className="mx-auto pt-2 text-left text-black">
        <div className="mx-auto hidden bg-blue bg-gray py-12 lg:w-3/4 2xl:w-1/2">
          DIGITAL AD HERE
        </div>
      </div>
    </section>
  );
};

export default AdBanner;
